:root {
  --headerHeight: 198px;
}
@media (max-width: 1399px) {
  :root {
    --headerHeight: 160px;
  }
}
@media (max-width: 1023px) {
  :root {
    --headerHeight: 120px;
  }
}
@media (max-width: 767px) {
  :root {
    --headerHeight: 74px;
  }
}
:root {
  --deskWidth: 1132px;
  --deskWidthLarge: 1820px;
  --deskSpace: 50px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1023px) {
  :root {
    --deskSpace: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 20px;
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 35px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 15px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 25px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.button:hover,
.button:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink .button,
.area--one .unitThree .button,
.area--one .unitFour--statusSold .button {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray .button,
.area--one .unitFour--statusRented .button {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  .button {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  .button {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
@font-face {
  font-family: "AkzidenzGroteskCondensed";
  font-weight: 300;
  font-style: normal;
  src: url('/extras/fonts/AkzidenzGroteskCondensedLight/font.woff2') format('woff2'), url('/extras/fonts/AkzidenzGroteskCondensedLight/font.woff') format('woff');
}
@font-face {
  font-family: "AkzidenzGroteskCondensed";
  font-weight: 700;
  font-style: normal;
  src: url('/extras/fonts/AkzidenzGroteskCondensedBold/font.woff2') format('woff2'), url('/extras/fonts/AkzidenzGroteskCondensedBold/font.woff') format('woff');
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #FAE7DA;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 700;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 22px;
  font-family: 'AkzidenzGroteskCondensed', sans-serif;
  line-height: 1.18181818;
}
.unit caption {
  display: none;
}
.flag {
  background: #FAE7DA;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.area .unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.unit .part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #929291;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 23.4375%;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #FAE7DA;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #FAE7DA;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'AkzidenzGroteskCondensed', sans-serif;
  line-height: 1.42857143;
  color: #929291;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #FAE7DA;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #FAE7DA;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink #disp .foot input,
.area--one .unitTwo--backgroundPink #disp .foot a,
.area--one .unitThree #disp .foot input,
.area--one .unitThree #disp .foot a,
.area--one .unitFour--statusSold #disp .foot input,
.area--one .unitFour--statusSold #disp .foot a {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray #disp .foot input,
.area--one .unitTwo--backgroundGray #disp .foot a,
.area--one .unitFour--statusRented #disp .foot input,
.area--one .unitFour--statusRented #disp .foot a {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  #disp .foot input,
  #disp .foot a {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'AkzidenzGroteskCondensed', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 7px;
    padding-bottom: 7px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 26px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 7px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'AkzidenzGroteskCondensed', sans-serif;
  font-size: 22px;
  line-height: 1.18181818;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid #929291;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop span::file-selector-button {
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  margin-right: 16px;
}
.unit.form .ship div.chop span::file-selector-button:hover,
.unit.form .ship div.chop span::file-selector-button:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink .unit.form .ship div.chop span::file-selector-button,
.area--one .unitThree .unit.form .ship div.chop span::file-selector-button,
.area--one .unitFour--statusSold .unit.form .ship div.chop span::file-selector-button {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray .unit.form .ship div.chop span::file-selector-button,
.area--one .unitFour--statusRented .unit.form .ship div.chop span::file-selector-button {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  .unit.form .ship div.chop span::file-selector-button {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  .unit.form .ship div.chop span::file-selector-button {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink .unit.form .submit,
.area--one .unitThree .unit.form .submit,
.area--one .unitFour--statusSold .unit.form .submit {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray .unit.form .submit,
.area--one .unitFour--statusRented .unit.form .submit {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  .unit.form .submit {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  .unit.form .submit {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #929291;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink .two-step-verification-container a,
.area--one .unitThree .two-step-verification-container a,
.area--one .unitFour--statusSold .two-step-verification-container a {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray .two-step-verification-container a,
.area--one .unitFour--statusRented .two-step-verification-container a {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  .two-step-verification-container a {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  .two-step-verification-container a {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  font-size: 45px;
  line-height: 1.11111111;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 700;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 700;
}
@media (max-width: 0) {
  .part--table .table {
    font-size: Infinityvw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 92px;
}
@media (max-width: 1399px) {
  #home {
    height: 74px;
  }
}
@media (max-width: 767px) {
  #home {
    height: 42px;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: var(--spaceTotal);
  text-align: center;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'AkzidenzGroteskCondensed', sans-serif;
  font-size: 22px;
  line-height: 1.18181818;
  color: #929291;
  accent-color: #FAE7DA;
  overflow: hidden;
  hyphens: none;
  box-sizing: border-box;
  padding-top: var(--headerHeight);
  display: flex;
  flex-direction: column;
  font-weight: 300;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--header .desk,
.section--title .desk,
.section--flowers .desk,
.section--footer .desk {
  width: var(--deskWidthLarge);
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0 9px 22px rgba(0, 0, 0, 0.16);
}
.section--marginTop {
  margin-top: auto;
}
.section--footer {
  margin-top: var(--spaceTotal);
  background-color: #929291;
}
.content {
  float: left;
  width: 100%;
}
.section--header .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--headerHeight);
}
.navigation {
  float: left;
}
@media (max-width: 1023px) {
  .navigation {
    display: none;
  }
}
.footcontent {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 20px;
  line-height: 1.4;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 136px;
}
.footcontent a {
  text-decoration: none;
}
@media (max-width: 767px) {
  .footcontent {
    font-size: 13px;
    line-height: 1.53846154;
    min-height: 80px;
  }
}
.footunit {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#social1,
#social2 {
  float: left;
}
#social1 .meta,
#social2 .meta {
  float: left;
  width: 40px;
  height: 40px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-instagram-white.svg);
  margin-right: 20px;
}
#social1 .meta:last-child,
#social2 .meta:last-child {
  margin-right: 0;
}
#social1 .meta:hover,
#social2 .meta:hover,
#social1 .meta:focus,
#social2 .meta:focus {
  background-image: url(/images/social-instagram-pink.svg);
}
#social1 .meta.service_linkedin,
#social2 .meta.service_linkedin {
  background-image: url(/images/social-linkedin-white.svg);
}
#social1 .meta.service_linkedin:hover,
#social2 .meta.service_linkedin:hover,
#social1 .meta.service_linkedin:focus,
#social2 .meta.service_linkedin:focus {
  background-image: url(/images/social-linkedin-pink.svg);
}
@media (max-width: 767px) {
  #social1 .meta,
  #social2 .meta {
    width: 26px;
    height: 26px;
  }
}
.copyright {
  float: left;
}
#services {
  float: left;
  margin-top: 8px;
}
#services .meta {
  float: left;
  margin-right: 20px;
}
#services .meta:last-child {
  margin-right: 0 !important;
}
@media (max-width: 767px) {
  #services {
    margin-top: 4px;
  }
}
a {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
a:hover,
a:focus {
  color: #FAE7DA;
}
p.norm {
  letter-spacing: 1px;
}
h1,
h2 {
  font-size: 80px;
  line-height: 1.1;
  font-weight: 300;
}
.pageTitleSize--small h1 {
  font-size: 40px;
  line-height: 1.15;
}
h3 {
  font-size: 60px;
  line-height: 1.16666667;
  font-weight: 300;
}
h4 {
  font-size: 45px;
  line-height: 1.11111111;
  font-weight: 300;
}
.pale {
  letter-spacing: 1px;
  padding-left: 26px;
  background-size: 11px 16px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/icon-pale-gray.svg);
}
.area--one .unitTwo--backgroundGray .pale,
.area--one .unitFour--statusRented .pale {
  background-image: url(/images/icon-pale-white.svg);
}
.area--one {
  display: flex;
  flex-wrap: wrap;
}
.area--one .unitTwo {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  box-sizing: border-box;
  padding: calc(20px - var(--spacePart)) 20px;
  box-shadow: 0 10px 6px rgba(0, 0, 0, 0.16);
}
@media (max-width: 767px) {
  .area--one .unitTwo {
    padding: calc(16px - var(--spacePart)) 16px;
  }
}
.area--one .unitTwo--1-1 {
  text-align: center;
}
.area--one .unitTwo--1-2 {
  display: flex;
  flex-direction: column;
}
.area--one .unitTwo--1-2 .unit__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.area--one .unitTwo--1-2 .unit__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.area--one .unitTwo--1-2 .part.pict {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.area--one .unitTwo--1-2 .part.pict:first-child {
  margin-top: calc((20px - var(--spacePart)) * -1);
  margin-bottom: calc(20px - var(--spacePart));
}
.area--one .unitTwo--1-2 .part--table:last-child {
  margin-top: auto;
  padding-top: var(--spacePart);
}
@media (max-width: 767px) {
  .area--one .unitTwo--1-2 .part.pict {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  .area--one .unitTwo--1-2 .part.pict:first-child {
    margin-top: calc((16px - var(--spacePart)) * -1);
    margin-bottom: calc(16px - var(--spacePart));
  }
}
.area--one .unitTwo--backgroundPink {
  background-color: #FAE7DA;
}
.area--one .unitTwo--backgroundPink a:hover,
.area--one .unitTwo--backgroundPink a:focus {
  color: #fff;
}
.area--one .unitTwo--backgroundGray {
  background-color: #929291;
  color: #fff;
}
.area--one .unitThree {
  margin: var(--spaceTotal) 0 !important;
  background-color: #FAE7DA;
}
.area--one .unitThree a:hover,
.area--one .unitThree a:focus {
  color: #fff;
}
.area--one .unitThree .unit__content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--one .unitThree .unit__body {
  width: 654px;
  max-width: calc(100% - 100px);
  padding-top: calc(50px - var(--spacePart));
  padding-bottom: calc(50px - var(--spacePart));
}
.area--one .unitThree .part.link {
  margin-top: calc(var(--spacePart) * 3);
}
.area--one .unitThree .part.link:first-child {
  margin-top: var(--spacePart);
}
@media (max-width: 1023px) {
  .area--one .unitThree .unit__body {
    max-width: calc(100% - 60px);
    padding-top: calc(30px - var(--spacePart));
    padding-bottom: calc(30px - var(--spacePart));
  }
}
@media (max-width: 767px) {
  .area--one .unitThree .unit__body {
    max-width: calc(100% - 40px);
    padding-top: calc(20px - var(--spacePart));
    padding-bottom: calc(20px - var(--spacePart));
  }
}
.area--one .unitFour {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  box-shadow: 0 10px 19px rgba(0, 0, 0, 0.16);
}
.area--one .unitFour + .unitFour + .unitFour {
  margin-top: calc((var(--spaceTotal) - 50px) * -1);
}
.area--one .unitFour .unit__background {
  aspect-ratio: 1.58857143;
  position: relative;
}
.area--one .unitFour .unit__background * {
  height: 100%;
}
.area--one .unitFour .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitFour .unit__background:after {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  font-size: 35px;
  line-height: 1.14285714;
  color: #929291;
  background-color: #fff;
  padding: 10px 24px;
}
.area--one .unitFour .unit__content {
  padding: 20px 24px;
  box-sizing: border-box;
}
.area--one .unitFour .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
@media (min-width: 1024px) {
  .area--one .unitFour .unit__background {
    filter: grayscale(1);
    transition: all 0.24s;
  }
  .area--one .unitFour:hover .unit__background {
    filter: grayscale(0);
  }
}
@media (max-width: 1023px) {
  .area--one .unitFour .unit__background:after {
    padding: 4px 16px;
  }
  .area--one .unitFour .unit__content {
    padding: 12px 16px;
  }
}
.area--one .unitFour--statusSold {
  background-color: #FAE7DA;
}
.area--one .unitFour--statusSold a:hover,
.area--one .unitFour--statusSold a:focus {
  color: #fff;
}
.area--one .unitFour--statusSold .unit__background:after {
  content: 'Verkauft';
}
.area--one .unitFour--statusRented {
  background-color: #929291;
  color: #fff;
}
.area--one .unitFour--statusRented .unit__background:after {
  content: 'Vermietet';
}
.part--link .open {
  display: inline-block;
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.part--link .open:hover,
.part--link .open:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink .part--link .open,
.area--one .unitThree .part--link .open,
.area--one .unitFour--statusSold .part--link .open {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray .part--link .open,
.area--one .unitFour--statusRented .part--link .open {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  .part--link .open {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  .part--link .open {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
.part--link.part--buttonSpaceNone {
  margin-top: calc(var(--spacePart) * -1);
}
.part--link.part--buttonColorGray .open {
  background-color: #929291;
  color: #fff;
}
.part--link.part--buttonColorGray .open:hover,
.part--link.part--buttonColorGray .open:focus {
  background-color: #FAE7DA;
  color: #929291;
}
.part--link.part--buttonAlignNext {
  width: auto;
  min-width: 100px;
  margin-right: 50px;
}
.part--link.part--buttonAlignNext:last-child {
  margin-right: 0 !important;
}
@media (max-width: 1023px) {
  .part--link.part--buttonAlignNext {
    margin-right: 30px;
  }
}
.part.cb-googlemaps,
.part.cb-googlemaps .cb-googlemapscontainer,
.part.cb-googlemaps .cb-undraggable {
  height: 460px !important;
}
@media (max-width: 767px) {
  .part.cb-googlemaps,
  .part.cb-googlemaps .cb-googlemapscontainer,
  .part.cb-googlemaps .cb-undraggable {
    height: 340px !important;
  }
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  float: left;
  position: relative;
  width: 22px;
  height: 18px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 2px;
  background-color: #929291;
  border-radius: 2px;
  transition: all 0.4s;
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #FAE7DA;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s;
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #929291;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #FAE7DA;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  margin-top: calc(var(--headerHeight) + 100px);
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-navigation div.sub1 > .item {
  margin-top: 40px;
}
.mobile-navigation div.sub1 > .item.init {
  margin-top: 0 !important;
}
.mobile-navigation div.sub1 > .item > .menu {
  font-size: 42px;
  line-height: 1.19047619;
  text-decoration: none;
  color: #929291;
  text-transform: uppercase;
}
.mobile-navigation div.sub1 > .item > .menu.path {
  color: #fff;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub1 {
    margin-top: calc(var(--headerHeight) + 60px);
    margin-bottom: 60px;
  }
}
.cb-album {
  position: relative;
  padding-top: var(--spacePart);
  padding-bottom: var(--spacePart);
}
.cb-album .body-mobile {
  display: none !important;
}
.cb-album h2 {
  display: none;
}
.cb-album .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cb-album .part {
  width: 100% !important;
  margin: 0 !important;
}
.cb-album .part.desc {
  display: none;
}
#root.ie11 .cb-album img {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-album.cb-album .scan {
  margin-top: 40px !important;
}
.cb-album.cb-album .scan:first-child {
  margin-top: 0 !important;
  margin-bottom: 40px !important;
}
.cb-album .scan table,
.cb-album .scan tbody,
.cb-album .scan tr {
  float: left;
  display: block;
  width: 100%;
}
.cb-matrix .body {
  margin-top: -0.5%;
  margin-bottom: -0.5%;
}
.cb-matrix .part {
  margin-top: 0.5% !important;
  margin-bottom: 0.5% !important;
}
.cb-matrix .part.foto {
  height: auto !important;
  box-sizing: border-box;
}
.cb-matrix .part.foto a.foto,
.cb-matrix .part.foto span.foto {
  display: block;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
}
.cb-matrix .part.foto img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-matrix .part.foto a.foto.zoom img {
  transform: scale(1.1);
}
.cb-matrix.cb-matrix-columns-1 .part.foto {
  width: 100% !important;
}
.cb-matrix.cb-matrix-columns-2 .part.foto {
  width: 49.5% !important;
}
.cb-matrix.cb-matrix-columns-3 .part.foto {
  width: 32.66666667% !important;
}
.cb-matrix.cb-matrix-columns-4 .part.foto {
  width: 24.25% !important;
}
.cb-matrix.cb-matrix-columns-5 .part.foto {
  width: 19.2% !important;
}
.cb-matrix.cb-matrix-columns-6 .part.foto {
  width: 15.83333333% !important;
}
.cb-matrix.cb-matrix-columns-7 .part.foto {
  width: 13.42857143% !important;
}
.cb-matrix.cb-matrix-columns-8 .part.foto {
  width: 11.625% !important;
}
.cb-matrix .scan tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.cb-matrix .scan td {
  float: left;
  display: block;
  margin: 0 10px;
  font-size: 16px;
  line-height: 1.25;
}
.cb-matrix .scan td:first-child {
  margin-left: 0;
}
.cb-matrix .scan td:last-child {
  margin-right: 0;
}
.cb-matrix .scan td.prev,
.cb-matrix .scan td.next {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 20px;
  height: 20px;
}
.cb-matrix .scan td.prev a,
.cb-matrix .scan td.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.cb-matrix .scan td.prev:empty,
.cb-matrix .scan td.next:empty {
  opacity: 0.5;
  pointer-events: none;
}
.cb-matrix .scan td.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-matrix .scan td a {
  color: #929291;
}
.cb-matrix .scan td .same {
  color: #FAE7DA;
}
.cb-strips .part.foto {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-strips-images {
  float: left;
  width: 100%;
  height: 0 !important;
  padding-bottom: 56.25%;
  position: relative;
}
.cb-strips-images a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.96);
  z-index: 2;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1), transform 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-images a.is-active {
  z-index: 3;
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
}
.cb-strips-images img {
  opacity: 1 !important;
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-strips .scan tbody > tr {
  float: left;
  width: 100%;
  display: flex;
}
.cb-strips .scan tbody > tr > td {
  float: left;
  width: 100%;
  display: block;
}
.cb-strips .scan td.prev,
.cb-strips .scan td.next {
  margin-right: 1.25%;
  width: 6.25%;
}
.cb-strips .scan td.next {
  margin-right: 0;
  margin-left: 1.25%;
}
.cb-strips .scan div.prev,
.cb-strips .scan div.next {
  float: left;
  width: 100%;
  height: 100%;
}
.cb-strips .scan div.prev a,
.cb-strips .scan div.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips .scan div.prev a:hover,
.cb-strips .scan div.next a:hover {
  background-position: 40% 50%;
}
.cb-strips .scan div.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-strips .scan div.next a:hover {
  background-position: 60% 50%;
}
.cb-strips .scan td.cb-strips-thumbs {
  width: 85%;
}
.cb-strips-container {
  float: left;
  width: 100%;
}
.cb-strips-container .body {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cb-strips-container .part.foto {
  position: relative;
}
.cb-strips-container .part.foto a.foto,
.cb-strips-container .part.foto span.foto {
  float: left;
  width: 100%;
  display: block;
  height: 0;
  position: relative;
  padding-bottom: 60%;
  opacity: 0.4;
  transition: opacity 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-container .part.foto a.foto:hover,
.cb-strips-container .part.foto span.foto:hover,
.cb-strips-container .part.foto a.foto.is-active,
.cb-strips-container .part.foto span.foto.is-active {
  opacity: 1;
}
.cb-strips-container .part.foto a.foto .cb-zoom-indicator,
.cb-strips-container .part.foto span.foto .cb-zoom-indicator {
  display: none;
}
.cb-strips-container .part.foto img {
  opacity: 1 !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips-container .part.foto a.foto:hover img {
  transform: scale(1.2);
}
.cb-strips-4 .cb-strips-container .part.foto {
  width: 23.95833333% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  width: 15.50925926% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  width: 11.28472222% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  width: 8.75% !important;
}
.cb-slideshow-images {
  float: left;
  width: 100%;
}
.cb-slideshow .part.foto,
.cb-slideshow .part.crossslideContainer {
  max-height: 1000000000px !important;
  max-width: 100% !important;
  height: 0 !important;
  padding-bottom: 75.08833922%;
  overflow: hidden;
  position: relative;
}
.cb-slideshow a.foto {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow a.foto.is-activated {
  z-index: 2;
  pointer-events: auto;
  opacity: 1;
}
.cb-slideshow img {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  border: none !important;
  z-index: 1;
}
.cb-slideshow img.is-activated {
  z-index: 2;
}
#view .cb-slideshow.cb-manual img {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-manual a.foto.is-activated img {
  transform: scale(1);
}
#view .cb-slideshow.cb-fading img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-fading img.is-activated,
#view .cb-slideshow.cb-fading .cb-image-figure.is-activated img {
  opacity: 1 !important;
  transform: scale(1);
}
#view .cb-slideshow.cb-sliding img {
  opacity: 0 !important;
  width: 110% !important;
  max-width: 110% !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowSlide;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-sliding img.is-activated,
#view .cb-slideshow.cb-sliding .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowSlide {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -10%;
  }
  100% {
    margin-left: 0;
  }
}
#view .cb-slideshow.cb-moving img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowMove;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-moving img.is-activated,
#view .cb-slideshow.cb-moving .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowMove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.cb-slideshow .head {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 4;
  height: 0;
  width: 100%;
}
.cb-slideshow .ctrl {
  float: left;
  width: 100%;
}
.cb-slideshow .ctrl a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  width: 50px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.6);
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow .ctrl a:hover {
  width: 70px;
}
.cb-slideshow .ctrl a.fade {
  opacity: 0.4;
  cursor: default;
  width: 50px;
}
.cb-slideshow .ctrl .next a {
  left: unset;
  right: 0;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.crossslide {
  float: left;
  width: 100%;
  max-width: 100% !important;
  position: static !important;
  padding: 0 !important;
}
@media (max-width: 1399px) {
  .wrapper {
    font-size: 18px;
    line-height: 1.33333333;
  }
  h1,
  h2 {
    font-size: 60px;
    line-height: 1.16666667;
  }
  h3 {
    font-size: 40px;
    line-height: 1.15;
  }
  h4 {
    font-size: 30px;
    line-height: 1.13333333;
  }
  .area--one .unitFour .unit__background:after {
    font-size: 26px;
    line-height: 1.15384615;
  }
  .table {
    font-size: 30px;
    line-height: 1.13333333;
  }
}
@media (max-width: 1023px) {
  .wrapper {
    font-size: 17px;
    line-height: 1.29411765;
  }
  h1,
  h2 {
    font-size: 46px;
    line-height: 1.13043478;
  }
  h3 {
    font-size: 30px;
    line-height: 1.13333333;
  }
  .area--one .unitFour .unit__background:after {
    font-size: 25px;
    line-height: 1.28;
  }
  .table {
    font-size: 26px;
    line-height: 1.15384615;
  }
}
@media (max-width: 767px) {
  h1,
  h2 {
    font-size: 30px;
    line-height: 1.13333333;
  }
  .pageTitleSize--small h1 {
    font-size: 30px;
    line-height: 1.13333333;
  }
}
.flowers {
  float: left;
  width: 100%;
  position: relative;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  display: none;
}
.layout2 .flowers {
  display: block;
}
.part--flowersImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.part--flowersImage * {
  height: 100% !important;
}
.part--flowersImage img {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important !important;
  max-height: 100% !important !important;
  object-fit: cover !important;
  object-position: center !important;
}
.part--flowersImage .placeholder {
  padding-bottom: 0;
}
.flowers__content {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 0 var(--deskSpace);
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
.part--flowersText {
  float: left;
  width: 100%;
  text-align: center;
}
.flowers__actions {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
@media (max-width: 1023px) {
  .flowers__actions {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .flowers__actions {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.flowers__actions .part.link {
  float: left;
  margin-right: 100px;
}
.flowers__actions .part.link:last-child {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
.flowers__actions .part.link .open {
  float: left;
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 60px;
  line-height: 1.4;
  border: 2px solid #929291;
  background-color: transparent;
  min-width: 480px;
  box-sizing: border-box;
}
.flowers__actions .part.link .open:hover,
.flowers__actions .part.link .open:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink .flowers__actions .part.link .open,
.area--one .unitThree .flowers__actions .part.link .open,
.area--one .unitFour--statusSold .flowers__actions .part.link .open {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray .flowers__actions .part.link .open,
.area--one .unitFour--statusRented .flowers__actions .part.link .open {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  .flowers__actions .part.link .open {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  .flowers__actions .part.link .open {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
@media (max-width: 1399px) {
  .flowers__actions .part.link .open {
    font-size: 40px;
    line-height: 1.15;
    min-width: 300px;
  }
}
@media (max-width: 1023px) {
  .flowers__actions .part.link .open {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px;
    min-width: 160px;
  }
}
@media (max-width: 1023px) {
  .flowers__actions .part.link {
    margin-right: 40px;
  }
}
@media (max-width: 767px) {
  .flowers__actions .part.link {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
/*# sourceMappingURL=./screen-small.css.map */